import React, {Component} from "react"
import axios from "axios"
import update from "immutability-helper"
import "react-circular-progressbar/dist/styles.css"
import {Spinner, Icon} from "AWS-UI-Components-React"

class ServiceIcon extends Component<any, any> {
  render() {
    switch (this.props.service) {
      case "direct":
        return "Direct over internet"
      case "ubiquity":
        return "AWS Global Accelerator"
      default:
        throw new Error("Unsupported service: " + this.props.service)
    }
  }
}

export default class PerformanceTest extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      state: "stopped",
      tcpWidth: "0%",
      warmFblWidth: "0%",
      lblWidth: "0%",
      tcp: [],
      fbl: [],
      lbl: [],
      sum: [],
      width: 0,
      failedTest: [false, false, false],
      numberOfRuns: 3,
      passedToParent: false,
      finishedTests: false,
    }
  }

  async componentDidUpdate() {
    if (this.props.run === "enabled" && this.state.state === "stopped") {
      this.setState(update(this.state, {state: {$set: "in-progress"}}))
      this.props.testStarted()
      for (let i = 0; i < this.state.numberOfRuns; i++) {
        let url = `https://${this.props.uuid}-${i}.${this.props.mode}.${this.props.region}.prod.endpoints.ubiquity.aws.a2z.com/api/burst/${this.props.size}`
        await axios
          .request({
            method: "get",
            url: url,
          })
          .catch(async e => {
            await axios
              .request({
                method: "get",
                url: url,
              })
              .catch(e => {
                let copyFailedArray = this.state.failedTest.slice()
                copyFailedArray[i] = true
                this.setState({failedTest: copyFailedArray})
              })
          })
      }

      await this.getTimings()
      this.setState(update(this.state, {state: {$set: "positive"}}))
    }
    if (this.state.finishedTests && !this.state.passedToParent) {
      this.setState({
        passedToParent: true,
      })
      this.props.onComplete(this.props.region, this.props.mode)
      // If there are no timings found, fail the test
      if (
        this.state.tcp.length === 0 ||
        this.state.fbl.length === 0 ||
        this.state.lbl.length === 0 ||
        this.state.sum.length === 0
      ) {
        this.props.updateSpeed(this.props.mode, true, 0, 0, 0, 0)
      } else {
        this.props.updateSpeed(
          this.props.mode,
          false,
          this.getAverage(this.state.tcp),
          this.getAverage(this.state.fbl),
          this.getAverage(this.state.lbl),
          this.getAverage(this.state.sum),
        )
      }
    }
  }

  getAverage(timings) {
    const total = timings.reduce((acc, c) => acc + c, 0)
    return total / timings.length
  }

  async getTimings() {
    for (let j = 0; j < this.state.numberOfRuns; j++) {
      await this.newInterval(j)
    }
  }

  async newInterval(iteration) {
    let url = `https://${this.props.uuid}-${iteration}.${this.props.mode}.${this.props.region}.prod.endpoints.ubiquity.aws.a2z.com/api/burst/${this.props.size}`
    const retries = 3
    let counter = 0
    let interval = await setInterval(async () => {
      if (this.state.failedTest[iteration]) {
        clearInterval(interval)
        if (iteration === this.state.numberOfRuns - 1) {
          this.setState({
            finishedTests: true,
          })
        }
        return
      }
      if (
        typeof performance.getEntriesByName(url)[0] === "undefined" &&
        counter < retries
      ) {
        counter++
        await axios
          .request({
            method: "get",
            url: url,
          })
          .catch(async e => {
            await axios
              .request({
                method: "get",
                url: url,
              })
              .catch(e => {
                let copyFailedArray = this.state.failedTest.slice()
                copyFailedArray[iteration] = true
                this.setState({failedTest: copyFailedArray})
              })
          })
        return
      } else if (
        typeof performance.getEntriesByName(url)[0] === "undefined" &&
        counter >= retries
      ) {
        // This tries to get the timings 3 times. If it fails on any of them, fails to output results
        clearInterval(interval)
        let copyFailedArray = this.state.failedTest.slice()
        copyFailedArray[iteration] = true
        this.setState({failedTest: copyFailedArray})
        if (iteration === this.state.numberOfRuns - 1) {
          this.setState({
            finishedTests: true,
          })
        }
        return
      }
      clearInterval(interval)
      const timings = performance.getEntriesByName(url)
      // If the first request fails, but the second request succeeds, there will be 2 times. Hence we use timings.length - 1
      const timingsJson = timings[timings.length - 1].toJSON()
      await this.setState({
        tcp: [
          ...this.state.tcp,
          timingsJson["connectEnd"] - timingsJson["connectStart"],
        ],
        fbl: [
          ...this.state.fbl,
          timingsJson["responseStart"] - timingsJson["requestStart"],
        ],
        lbl: [
          ...this.state.lbl,
          timingsJson["responseEnd"] - timingsJson["responseStart"],
        ],
        sum: [
          ...this.state.sum,
          timingsJson["connectEnd"] -
            timingsJson["connectStart"] +
            timingsJson["responseStart"] -
            timingsJson["requestStart"] +
            timingsJson["responseEnd"] -
            timingsJson["responseStart"],
        ],
      })
      if (iteration === this.state.numberOfRuns - 1) {
        this.setState({
          finishedTests: true,
        })
      }
    }, 1000)
  }

  render() {
    return (
      <div
        style={{
          padding: "0",
          margin: "0",
          paddingLeft: "20px",
          whiteSpace: "nowrap",
        }}
      >
        <div className="awsui-row awsui-util-no-gutters">
          <div
            className="col-12"
            style={{
              textAlign: "left",
              minWidth: "-webkit-min-content",
              paddingBottom: "0",
              paddingTop: "20px",
            }}
          >
            <ServiceIcon service={this.props.mode} />
          </div>
        </div>
        <div
          className="awsui-row awsui-util-no-gutters"
          style={{display: "flex", paddingLeft: "10px"}}
        >
          {this.props.failedTests ? (
            <div
              id="scaleSmaller"
              style={{paddingTop: "2px", flex: 1, whiteSpace: "nowrap"}}
            >
              {" "}
              <span className="awsui-util-status-negative">
                <Icon name="status-negative" /> Error. Refresh to try again.
              </span>
            </div>
          ) : this.props.running && !this.props.bothFinished ? (
            <div style={{paddingTop: "2px", flex: 1}}>
              <span className="awsui-util-status-inactive">
                <Spinner /> Running
              </span>
            </div>
          ) : this.props.bothFinished ? (
            <div style={{paddingTop: "2px", flex: 1}}>
              <div
                style={{
                  backgroundColor: "#00a1c9",
                  width: (this.props.width * 100).toLocaleString() + "%",
                  left: 0,
                  right: 0,
                  height: "14px",
                  textAlign: "center",
                  borderRadius: "2px",
                }}
              ></div>
            </div>
          ) : (
            <div style={{paddingTop: "2px", flex: 1}}>
              <span className="awsui-util-status-inactive">
                <Icon name="status-pending" /> Pending
              </span>
            </div>
          )}

          <div
            style={{
              flex: "0 0 100px",
              textAlign: "right",
              paddingRight: "20px",
              whiteSpace: "nowrap",
            }}
          >
            {this.props.failedTests ||
            (this.props.running && !this.props.bothFinished)
              ? 0
              : this.props.bothFinished
              ? this.props.totalDirect
                ? this.props.totalDirect
                : this.props.totalAga
              : 0}
            ms
          </div>
        </div>
      </div>
    )
  }
}
