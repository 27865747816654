import React, {Component} from "react"
import {AppLayout, Icon, ButtonDropdown, Button} from "AWS-UI-Components-React"
import update from "immutability-helper"
import uuid from "react-uuid"

import Card from "../components/Card"
import axios from "axios"

export default class PerformancePage extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      "us-east-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "us-east-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "us-west-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "us-west-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "af-south-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-west-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-west-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-south-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-south-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-west-3": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-central-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-central-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-northeast-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-northeast-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-northeast-3": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-southeast-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-southeast-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-southeast-3": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-southeast-4": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-south-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-south-2": {
        ubiquity: "pending",
        direct: "pending",
      },
      "eu-north-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "me-central-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "me-south-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ap-east-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "sa-east-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ca-central-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "ca-west-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      "il-central-1": {
        ubiquity: "pending",
        direct: "pending",
      },
      showMore: false,
      itemsToShow: 7,
      regions: [
        "us-east-1",
        "us-west-2",
        "eu-west-1",
        "eu-central-1",
        "eu-central-2",
        "ap-northeast-1",
        "ap-southeast-1",
        "ap-southeast-2",
        "ap-southeast-3",
        "ap-southeast-4",
        "us-west-1",
        "eu-west-2",
        "us-east-2",
        "eu-west-3",
        "ap-south-1",
        "ap-south-2",
        "eu-north-1",
        "me-central-1",
        "me-south-1",
        "ap-east-1",
        "sa-east-1",
        "ca-central-1",
        "ca-west-1",
        "ap-northeast-2",
        "ap-northeast-3",
        "eu-south-1",
        "eu-south-2",
        "af-south-1",
        "il-central-1",
      ],
      downloadSize: this.defaultDownloadSize,
      selectedSize:
        parseInt(String(localStorage.getItem("downloadSize"))) ||
        this.defaultDownloadSize,
      ingressPop: "unknown",
      uuid: `${uuid()}`,
      stripes: ["blue", "green"],
      braids: ["a", "b", "c", "d"],
      startDisabled: true,
      started: false,
    }
    this.showMore = this.showMore.bind(this)
    this.changeDownloadSize = this.changeDownloadSize.bind(this)
  }

  downloadSizes = new Map([
    [51200, "50KB"],
    [102400, "100KB"],
    [1024000, "1MB"],
    [2048000, "2MB"],
    [5120000, "5MB"],
  ])
  defaultDownloadSize = 102400

  async componentDidMount() {
    document.title = "AWS Global Accelerator Speed Comparison"
    if (this.state.ingressPop === "unknown") {
      await axios
        .request({
          method: "get",
          url:
            window.location.protocol +
            `//${
              this.state.stripes[
                Math.floor(Math.random() * this.state.stripes.length)
              ]
            }-${
              this.state.braids[
                Math.floor(Math.random() * this.state.braids.length)
              ]
            }.diagnostics.globalaccelerator.aws/api/stack`,
        })
        .then(async (response: any) => {
          await this.setState({
            ingressPop: response.data.stack,
            startDisabled: false,
          })
        })
        .catch(async () => {
          //catch error and continue the tests, although the stack will be failed
          await this.setState({
            ingressPop: "failed",
            startDisabled: false,
          })
        })
    }
  }

  stateChangeManager(event: any) {
    this.setState({[event.name]: event.value})
  }

  testComplete(region: string, mode: string) {
    for (var i = 0; i < this.state.regions.length; i++) {
      var currRegion = this.state.regions[i]
      if (region === currRegion) {
        if (mode === "direct") {
          var currValue = this.state[currRegion]
          currValue["ubiquity"] = "enabled"
          var currEvent = {
            name: currRegion,
            value: currValue,
          }
          this.stateChangeManager(currEvent)
        }
        var nextRegion = this.state.regions[i + 1]
        if (mode === "post" && i + 1 !== this.state.regions.length) {
          var nextValue = this.state[nextRegion]
          nextValue["direct"] = "enabled"
          var nextEvent = {
            name: nextRegion,
            value: nextValue,
          }
          this.stateChangeManager(nextEvent)
        }
      }
    }
  }

  showMore() {
    this.setState({showMore: !this.state.showMore})
  }

  changeDownloadSize = (event: any) => {
    localStorage.setItem("downloadSize", event.detail.id)
    this.setState({
      selectedSize: parseInt(event.detail.id),
    })
  }

  startTests = async () => {
    await this.setState({
      downloadSize: this.state.selectedSize,
    })
    await this.setState(
      update(this.state, {"us-east-1": {direct: {$set: "enabled"}}}),
    )
    this.setState({
      started: true,
    })
  }

  stopTests = () => {
    window.location.reload()
  }

  render() {
    const regionNameMap: {[index: string]: any} = {
      "us-east-1": "N. Virginia",
      "us-west-2": "Oregon",
      "us-west-1": "N. California",
      "us-east-2": "Ohio",
      "eu-west-1": "Ireland",
      "eu-west-2": "London",
      "eu-west-3": "Paris",
      "eu-central-1": "Frankfurt",
      "eu-central-2": "Zurich",
      "ap-northeast-1": "Tokyo",
      "ap-northeast-2": "Seoul",
      "ap-northeast-3": "Osaka",
      "ap-southeast-1": "Singapore",
      "ap-southeast-2": "Sydney",
      "ap-southeast-3": "Jakarta",
      "ap-southeast-4": "Melbourne",
      "ap-south-1": "Mumbai",
      "ap-south-2": "Hyderabad",
      "eu-north-1": "Stockholm",
      "me-central-1": "UAE",
      "me-south-1": "Bahrain",
      "ap-east-1": "Hong Kong",
      "sa-east-1": "São Paulo",
      "ca-central-1": "Canada",
      "ca-west-1": "Calgary",
      "af-south-1": "Cape Town",
      "eu-south-1": "Milan",
      "eu-south-2": "Spain",
      "il-central-1": "Tel Aviv",
    }

    return (
      <AppLayout
        content={
          <div className="awsui-grid" style={{paddingTop: "0px"}}>
            <div
              className="col-12 awsui-util-t-c logo"
              style={{
                background: "no-repeat",
                backgroundImage: `url(//d1.awsstatic.com/product-marketing/Ubiquity/ImgHead_Ubiquity.92e1d54a6fa5db11c893fe132c7f42ec4deacbe0.jpg)`,
                backgroundSize: "cover",
                backgroundPosition: "0 0px",
                paddingLeft: "40px",
                paddingBottom: "10px",
                boxSizing: "border-box",
              }}
            >
              <h1
                style={{
                  textAlign: "left",
                  marginLeft: "10px",
                  paddingTop: "20px",
                }}
              >
                <span
                  className="awsui-util-font-size-4"
                  style={{
                    fontWeight: 700,
                    color: "#fff",
                    fontSize: "4.4rem",
                    wordWrap: "break-word",
                  }}
                >
                  AWS Global Accelerator
                </span>
                <div
                  style={{
                    color: "#fff",
                    fontSize: "2.4rem",
                    fontWeight: 300,
                    wordWrap: "break-word",
                  }}
                >
                  Speed Comparison
                </div>
              </h1>
            </div>
            <div className="awsui-row" style={{paddingTop: "20px"}}>
              <div
                className="col-6 col-s-12 col-xs-12 col-xxs-12 col-xxxs-12 col-l-6 col-xl-4 col-xxl-4 col-xxxl-4 about"
                style={{
                  borderRight: "1px solid rgba(170, 170, 170, 0.44)",
                }}
              >
                <h2 style={{margin: "-8px 0 5px"}}>About this tool</h2>
                <p>
                  <a
                    href="https://aws.amazon.com/global-accelerator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AWS Global Accelerator
                  </a>{" "}
                  is a service that improves the availability and performance of
                  your applications. This tool compares Global Accelerator to
                  the public internet. Choose a file size to see the time to
                  download a file from application endpoints in different AWS
                  Regions to your browser.
                </p>
                <p>
                  Files are downloaded over HTTPS/TCP from Application Load
                  Balancers (ALBs) in different AWS Regions to your browser.{" "}
                  <a
                    href="https://docs.aws.amazon.com/global-accelerator/latest/dg/introduction-speed-comparison-tool.html"
                    target="_blank>"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </p>
                <p>
                  Choose a file size and click "Start" to start the tests:
                  <ButtonDropdown
                    id="size"
                    className="awsui-util-pl-xs"
                    items={Array.from(this.downloadSizes.keys()).map(key => {
                      return {
                        id: String(key),
                        text: String(this.downloadSizes.get(key)),
                      }
                    })}
                    disabled={this.state.started}
                    onItemClick={this.changeDownloadSize}
                  >
                    {this.downloadSizes.get(this.state.selectedSize)}
                  </ButtonDropdown>
                  <Button
                    id="start"
                    disabled={this.state.startDisabled}
                    variant="primary"
                    onClick={
                      this.state.started ? this.stopTests : this.startTests
                    }
                  >
                    {this.state.started ? "Reset" : "Start"}
                  </Button>
                </p>
                <p>
                  We welcome suggestions for how to improve this tool.{" "}
                  <a
                    href="https://docs-feedback.aws.amazon.com/feedback.jsp?feedback_destination_id=099b2743-3502-4621-bdab-8d7480c04921&topic_url=https://speedtest.globalaccelerator.aws"
                    target="_blank>"
                    rel="noopener noreferrer"
                  >
                    Provide feedback
                  </a>
                </p>
                <p style={{color: "var(--awsui-color-text-icon-subtle)"}}>
                  <Icon variant="subtle" name="status-info" />{" "}
                  <span style={{paddingRight: "2px"}} />
                  Results may differ when you run the test multiple times.
                  Download times can vary based on factors that are external to
                  Global Accelerator, such as the quality, capacity, and
                  distance of the connection in the last-mile network that
                  you’re using.
                </p>
              </div>
              {this.state.regions
                .slice(0, this.state.itemsToShow)
                .map((region: string, index: number) => {
                  return (
                    <Card
                      region={this.state.regions[index]}
                      regionName={regionNameMap[this.state.regions[index]]}
                      key={index}
                      size={this.state.downloadSize}
                      testComplete={this.testComplete.bind(this)}
                      runDirect={
                        this.state[this.state.regions[index]]["direct"]
                      }
                      runUbiquity={
                        this.state[this.state.regions[index]]["ubiquity"]
                      }
                      uuid={this.state.uuid}
                      ingressPop={this.state.ingressPop}
                      block={true}
                    />
                  )
                })}
              {this.state.regions
                .slice(this.state.itemsToShow)
                .map((region: string, index: number) => {
                  var modifiedIndex = this.state.itemsToShow + index
                  return (
                    <Card
                      region={this.state.regions[modifiedIndex]}
                      regionName={
                        regionNameMap[this.state.regions[modifiedIndex]]
                      }
                      key={modifiedIndex}
                      size={this.state.downloadSize}
                      testComplete={this.testComplete.bind(this)}
                      runDirect={
                        this.state[this.state.regions[modifiedIndex]]["direct"]
                      }
                      runUbiquity={
                        this.state[this.state.regions[modifiedIndex]][
                          "ubiquity"
                        ]
                      }
                      uuid={this.state.uuid}
                      ingressPop={this.state.ingressPop}
                      block={this.state.showMore}
                    />
                  )
                })}
            </div>
            <button
              id="button"
              type="button"
              className="awsui-button"
              onClick={this.showMore}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              {this.state.showMore ? "Show fewer Regions" : "Show more Regions"}
            </button>
            <div className="awsui-row" style={{marginTop: "20px"}}>
              Trace ID: {this.state.uuid}
            </div>
          </div>
        }
        navigationHide={true}
        toolsHide={true}
      />
    )
  }
}
