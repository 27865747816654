import React, {Component} from "react"
import PerformanceTest from "./PerformanceTest"
import axios from "axios"

export default class Card extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      tcpConnectTimeDirect: 0,
      tcpConnectTimeAga: 0,
      warmFblDirect: 0,
      warmFblAga: 0,
      lblDirect: 0,
      lblAga: 0,
      totalDirect: 0,
      totalAga: 0,
      difference: 0,
      directWidth: 0,
      agaWidth: 0,
      agaFinished: false,
      directFinished: false,
      diffFinished: false,
      differenceColor: "black",
      failedTests: false,
      updateDone: false,
      started: false,
    }
  }

  async updateSpeed(mode: string, failedTest: boolean, tcp, fbl, lbl, sum) {
    if (failedTest || this.state.failedTests) {
      this.setState({failedTests: true})
      if (mode === "direct") {
        this.setState({directFinished: true})
      } else {
        this.setState({agaFinished: true})
      }
      return
    }
    if (mode === "ubiquity") {
      this.setState({
        tcpConnectTimeAga: tcp,
        warmFblAga: fbl,
        lblAga: lbl,
        totalAga: sum,
        agaFinished: true,
      })
    } else {
      this.setState({
        tcpConnectTimeDirect: tcp,
        warmFblDirect: fbl,
        lblDirect: lbl,
        totalDirect: sum,
        directFinished: true,
      })
    }
  }

  async componentDidUpdate() {
    if (
      this.state.agaFinished &&
      this.state.directFinished &&
      !this.state.updateDone
    ) {
      this.setState({
        updateDone: true,
      })
      if (this.state.failedTests) {
        this.props.testComplete(this.props.region, "post")
        return
      }
      await this.updateDifference()
    }
  }

  async updateDifference() {
    await axios
      .request({
        method: "post",
        url: `/api/performance_data`,
        data: {
          Uuid: this.props.uuid,
          EndpointRegion: this.props.region,
          IngressPop: this.props.ingressPop,
          TcpConnectTimeDirect: parseFloat(
            this.state.tcpConnectTimeDirect.toFixed(2),
          ),
          TcpConnectTimeUbiquity: parseFloat(
            this.state.tcpConnectTimeAga.toFixed(2),
          ),
          TcpRequestTimeDirect: parseFloat(this.state.warmFblDirect.toFixed(2)),
          TcpRequestTimeUbiquity: parseFloat(this.state.warmFblAga.toFixed(2)),
          TcpResponseTimeDirect: parseFloat(this.state.lblDirect.toFixed(2)),
          TcpResponseTimeUbiquity: parseFloat(this.state.lblAga.toFixed(2)),
          DownloadSize: parseInt(this.props.size),
        },
      })
      .then(() => {
        this.props.testComplete(this.props.region, "post")
      })
      .catch(() => {
        //this is here so the page will continue to load for the user,
        //even if it fails to write the data to the db
        this.props.testComplete(this.props.region, "post")
      })
    if (this.state.totalDirect > this.state.totalAga) {
      this.setState({
        difference:
          (
            ((this.state.totalDirect - this.state.totalAga) /
              this.state.totalDirect) *
            100
          ).toFixed(0) + "% faster with AWS Global Accelerator",
        directWidth: 1,
        agaWidth: this.state.totalAga / this.state.totalDirect,
        diffFinished: true,
        differenceColor: "green",
      })
    } else {
      this.setState({
        difference:
          (
            ((-1 * (this.state.totalDirect - this.state.totalAga)) /
              this.state.totalDirect) *
            100
          ).toFixed(0) + "% slower with AWS Global Accelerator",
        directWidth: this.state.totalDirect / this.state.totalAga,
        agaWidth: 1,
        diffFinished: true,
        differenceColor: "red",
      })
    }
  }

  testStarted() {
    this.setState({
      started: true,
    })
  }

  render() {
    var cardClass =
      "col-6 col-s-12 col-xs-12 col-xxs-12 col-xxxs-12 col-l-6 col-xl-4 col-xxl-4 col-xxxl-4"
    return (
      <div
        className={cardClass}
        id={this.props.region}
        style={{display: this.props.block ? "block" : "none"}}
      >
        <div className="awsui-util-container" style={{height: "235px"}}>
          <div className="awsui-util-container-header">
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>
                  {this.props.regionName}{" "}
                  <span className="region-name">({this.props.region})</span>
                </h2>
              </div>
            </div>
          </div>
          <div
            className="awsui-row"
            style={{
              whiteSpace: "nowrap",
              position: "absolute",
              right: "1.8rem",
            }}
          >
            <div
              className="awsui-util-label"
              style={{
                textAlign: "right",
                position: "relative",
                marginTop: "15px",
              }}
            >
              Total time
            </div>
          </div>
          <PerformanceTest
            region={this.props.region}
            mode="direct"
            size={this.props.size}
            run={this.props.runDirect}
            onComplete={this.props.testComplete}
            updateSpeed={this.updateSpeed.bind(this)}
            width={this.state.directWidth}
            finished={this.state.diffFinished}
            uuid={this.props.uuid}
            totalDirect={this.state.totalDirect.toFixed(0)}
            bothFinished={this.state.agaFinished && this.state.directFinished}
            failedTests={this.state.failedTests}
            testStarted={this.testStarted.bind(this)}
            running={this.state.started}
          />
          <PerformanceTest
            region={this.props.region}
            mode="ubiquity"
            size={this.props.size}
            run={this.props.runUbiquity}
            onComplete={this.props.testComplete}
            updateSpeed={this.updateSpeed.bind(this)}
            width={this.state.agaWidth}
            finished={this.state.diffFinished}
            uuid={this.props.uuid}
            totalAga={this.state.totalAga.toFixed(0)}
            bothFinished={this.state.agaFinished && this.state.directFinished}
            failedTests={this.state.failedTests}
            testStarted={this.testStarted.bind(this)}
            running={this.state.started}
          />
          <div
            className="awsui-row"
            style={{
              whiteSpace: "nowrap",
              marginLeft: "auto",
              marginRight: "0",
              display: "block",
            }}
          >
            <div
              id={"scale"}
              style={{
                textAlign: "right",
                position: "relative",
                color: this.state.differenceColor,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <strong>{this.state.difference}</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
