import React, {Component} from "react"
import {HashRouter, Redirect, Route, Switch} from "react-router-dom"

import PerformancePage from "./pages/Performance"
import RoutingPage from "./pages/Routing"

import "AWS-UI-Components-React/index.css"
import "./App.css"

export default class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="awsui">
          <Switch>
            <Route exact path="/speedtest" component={PerformancePage} />
            <Route exact path="/routing" component={RoutingPage} />
            <Route
              path="/"
              render={props => {
                const [subdomain] = window.location.hostname.split(".")
                if (subdomain === "speedtest")
                  return <PerformancePage {...props} />
                if (subdomain === "diagnostics")
                  return <RoutingPage {...props} />
              }}
            />
            <Redirect to="/" />
          </Switch>
        </div>
      </HashRouter>
    )
  }
}
