import React, {Component} from "react"
import {Button} from "AWS-UI-Components-React"

export default class StatusIcon extends Component<any, any> {
  render() {
    switch (this.props.state) {
      case "in-progress":
        return <Button variant="icon" icon="status-in-progress" />
      case "info":
        return <Button variant="icon" icon="status-info" />
      case "negative":
        return <Button variant="icon" icon="status-negative" />
      case "pending":
        return <Button variant="icon" icon="status-pending" />
      case "positive":
        return <Button variant="icon" icon="status-positive" />
      case "stopped":
        return <Button variant="icon" icon="status-stopped" />
      case "warning":
        return <Button variant="icon" icon="status-warning" />
      default:
        throw new Error("Unsupported state: " + this.props.state)
    }
  }
}
