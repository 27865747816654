import React, {Component} from "react"
import {AppLayout, ColumnLayout} from "AWS-UI-Components-React"

import StatusIcon from "../components/StatusIcon"

export default class RoutingPage extends Component<any, any> {
  _isMounted = false

  constructor(props: any) {
    super(props)
    this.state = {
      state: "stopped",
      uuid: "-",
      client: {
        ip: "-",
      },
      zone1a: {
        ip: "-",
        stack: "-",
      },
      zone1b: {
        ip: "-",
        stack: "-",
      },
      zone1c: {
        ip: "-",
        stack: "-",
      },
      zone1d: {
        ip: "-",
        stack: "-",
      },
      zone2a: {
        ip: "-",
        stack: "-",
      },
      zone2b: {
        ip: "-",
        stack: "-",
      },
      zone2c: {
        ip: "-",
        stack: "-",
      },
      zone2d: {
        ip: "-",
        stack: "-",
      },
    }
  }

  componentDidMount() {
    document.title = "Global Accelerator Diagnostics"
    this._isMounted = true
    this.setState({state: "in-progress"})
    let urls = ["/api/results", "/api/client"]
    if (window.location.hostname.includes("gamma")) {
      urls = urls.concat([
        window.location.protocol +
          "//blue-a.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-b.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-c.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-d.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-a.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-b.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-c.diagnostics.gamma.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-d.diagnostics.gamma.globalaccelerator.aws/api/stack",
      ])
      /*
          } else if (window.location.hostname.includes('localhost')) {
            urls = urls.concat([
              '/api/stack',
              '/api/stack',
              '/api/stack',
              '/api/stack',
              '/api/stack',
              '/api/stack',
              '/api/stack',
              '/api/stack',
            ])
             */
    } else {
      urls = urls.concat([
        window.location.protocol +
          "//blue-a.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-b.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-c.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//blue-d.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-a.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-b.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-c.diagnostics.globalaccelerator.aws/api/stack",
        window.location.protocol +
          "//green-d.diagnostics.globalaccelerator.aws/api/stack",
      ])
    }

    Promise.all(
      urls.map(url =>
        fetch(url).catch(function(err: Error) {
          return err
        }),
      ),
    )
      .then(responses =>
        Promise.all(
          responses.map(r =>
            r instanceof Error
              ? r
              : r.json().catch(function(err: Error) {
                  return err
                }),
          ),
        ),
      )
      .then(results => {
        if (this._isMounted) {
          if (results[0] != null) {
            this.setState({uuid: results[0].uuid})
          }
          if (results[1] != null) {
            this.setState({client: results[1]})
          }
          if (results[2] != null) {
            this.setState({zone1a: results[2]})
          }
          if (results[3] != null) {
            this.setState({zone1b: results[3]})
          }
          if (results[4] != null) {
            this.setState({zone1c: results[4]})
          }
          if (results[5] != null) {
            this.setState({zone1d: results[5]})
          }
          if (results[6] != null) {
            this.setState({zone2a: results[6]})
          }
          if (results[7] != null) {
            this.setState({zone2b: results[7]})
          }
          if (results[8] != null) {
            this.setState({zone2c: results[8]})
          }
          if (results[9] != null) {
            this.setState({zone2d: results[9]})
          }
        }
        fetch("/api/results", {
          method: "post",
          body: JSON.stringify(this.state),
        })
          .then(results => {
            if (this._isMounted) {
              this.setState({state: "positive"})
            }
          })
          .catch(err => {
            if (this._isMounted) {
              this.setState({state: "negative"})
            }
          })
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <AppLayout
        content={
          <div className="awsui-grid">
            <div className="awsui-row">
              <div className="col-12 awsui-util-t-c">
                <h1 className="awsui-text-large">
                  <strong>AWS Global Accelerator</strong>
                </h1>
              </div>
            </div>
            <div className="awsui-row">
              <div className="col-12">
                <div className="awsui-util-container">
                  <div className="awsui-util-container-header">
                    <div className="awsui-util-action-stripe">
                      <div className="awsui-util-action-stripe-title">
                        <h2 id="traceId">Trace ID: {this.state.uuid}</h2>
                      </div>
                      <div className="awsui-util-action-stripe-group">
                        <StatusIcon state={this.state.state} />
                      </div>
                    </div>
                  </div>
                  <ColumnLayout columns={3} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                      <div>
                        <div className="awsui-util-spacing-v-s">
                          <div>
                            <div className="awsui-util-label">Client IP</div>
                            <div>{this.state.client.ip}</div>
                          </div>
                          <div>
                            <div className="awsui-util-label">Location</div>
                            <div>
                              {this.state.client.city}/{this.state.client.state}
                              /{this.state.client.country}
                            </div>
                          </div>
                          <div>
                            <div className="awsui-util-label">
                              Internet Provider
                            </div>
                            <div>
                              {this.state.client.autonomous_system_organization}{" "}
                              ({this.state.client.autonomous_system_number})
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="awsui-util-spacing-v-s">
                          <div>
                            <div className="awsui-util-label">
                              Network Zone 1
                            </div>
                            <div id="zone1a">{this.state.zone1a.stack}</div>
                            <div id="zone1b">{this.state.zone1b.stack}</div>
                            <div id="zone1c">{this.state.zone1c.stack}</div>
                            <div id="zone1d">{this.state.zone1d.stack}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="awsui-util-spacing-v-s">
                          <div>
                            <div className="awsui-util-label">
                              Network Zone 2
                            </div>
                            <div id="zone2a">{this.state.zone2a.stack}</div>
                            <div id="zone2b">{this.state.zone2b.stack}</div>
                            <div id="zone2c">{this.state.zone2c.stack}</div>
                            <div id="zone2d">{this.state.zone2d.stack}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ColumnLayout>
                </div>
              </div>
            </div>
          </div>
        }
        navigationHide={true}
        toolsHide={true}
      />
    )
  }
}
